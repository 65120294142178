import React from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  Stack,
  Avatar,
  TextField,
  Select,
  MenuItem,
  CardContent,
  IconButton,
} from "@mui/material";
import { AddButton } from "components";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Notes from "components/requests/Notes";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

// Define the type for the request data
interface RequestData {
  case_id?: number | null;
  case_type?: string | null;
  created_at?: string | null;
  defendant_name?: string | null;
  description?: string | null;
  document_type?: string | null;
  id?: number;
  judgment_document_path?: string | null;
  lawyer_id?: number | null;
  location?: string | null;
  request_type?: string | null;
}

// Props types for child components
interface ComponentProps {
  data: RequestData | null;
}

const ProfileCardComponent: React.FC<ComponentProps> = ({ data }) => (
  <Card sx={{ padding: "20px", display: "flex", alignItems: "center" }}>
    <Avatar
      alt="Profile Picture"
      src="/path/to/profile.jpg"
      sx={{ width: 64, height: 64, mr: 2 }}
    />
    <Box sx={{ mr: 2 }}>
      <Typography variant="h6">{data?.defendant_name || "N/A"}</Typography>
      <Typography variant="body2">المملكة العربية السعودية</Typography>
    </Box>
    <Button
      variant="contained"
      sx={{
        mt: 1,
        background: "#2e6cb6",
        mr: 20,
      }}
    >
      تواصل الآن
    </Button>
  </Card>
);

const MessageSectionComponent: React.FC<ComponentProps> = ({ data }) => (
  <Card sx={{ padding: "20px" }}>
    <Box sx={{ justifyContent: "space-between", display: "flex" }}>
      <Typography
        variant="body1"
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Avatar
          alt="Profile Picture"
          src="/message-icon@2x.png"
          sx={{ width: 30, height: 30, ml: 2 }}
        />
        رسالة العميل
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {data?.created_at || "N/A"}
      </Typography>
    </Box>
    <Box
      sx={{
        mt: 2,
        padding: "10px",
        bgcolor: "#f9f9f9",
        borderRadius: 2,
        height: "300px",
      }}
    >
      {data?.description || "No message provided..."}
    </Box>
  </Card>
);

const CaseInfoComponent: React.FC<ComponentProps> = ({ data }) => (
  <Card
    sx={{
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      gap: 2,
      direction: "ltr",
    }}
  >
    <Stack direction="row" spacing={2}>
      <TextField
        label="نوع القضية"
        defaultValue={data?.case_type || "N/A"}
        fullWidth
      />
      <TextField
        label="اسم المدعي"
        defaultValue={data?.defendant_name || "N/A"}
        fullWidth
      />
    </Stack>
    <Stack direction="row" spacing={2}>
      <TextField
        label="المنطقة"
        defaultValue={data?.location || "N/A"}
        fullWidth
      />
      <TextField
        label="قيمة المطالبة"
        defaultValue={data?.case_type || "N/A"}
        fullWidth
      />
    </Stack>
  </Card>
);

const DocumentsSectionComponent: React.FC<ComponentProps> = ({ data }) => (
  <Card sx={{ padding: "20px" }}>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Typography variant="h6">المستندات</Typography>
      <AddButton
        title={" إضافة مستند"}
        backgroundColor="#2E6CB6"
        color="#fcfcfc"
        icon={
          <img
            className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
            alt=""
            src={"/Group 239.svg"}
          />
        }
      />
    </Box>
    <Stack flexDirection={"row"} justifyContent={"space-between"}>
      <Box display="flex" alignItems="center">
        <Typography variant="body2">
          {data?.document_type ? `${data.document_type}.مستند` : "No documents"}
        </Typography>
      </Box>
      <Box>
        <IconButton>
          <SaveAltIcon style={{ color: "#8DA7C8" }} />
        </IconButton>
        <IconButton>
          <DeleteIcon style={{ color: "#8DA7C8" }} />
        </IconButton>
        <IconButton>
          <InfoIcon style={{ color: "#8DA7C8" }} />
        </IconButton>
      </Box>
    </Stack>
  </Card>
);

const RequestDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const data: RequestData | null = location.state?.request || null;
  console.log("🚀 ~ data:", data);

  const HeaderComponent: React.FC = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Select
        sx={{
          color: "#8DA7C8",
          borderColor: "#8DA7C8",
          width: "50%",
        }}
        variant="outlined"
        defaultValue="open"
      >
        <MenuItem value="open">مفتوح</MenuItem>
        <MenuItem value="closed">قيد العمل</MenuItem>
        <MenuItem value="inProgress">مكتملة</MenuItem>
      </Select>
      <AddButton
        title={"اسند الى محامي"}
        handleClick={() => navigate("/Requests/create")}
        backgroundColor="#28876b"
        color="#fcfcfc"
        icon={
          <img
            className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
            alt=""
            src={"/Group 239.svg"}
          />
        }
      />
    </Box>
  );

  return (
    <Box>
      <Typography fontSize={48} fontWeight={700} color="#11142d">
        {t("Requests")}
      </Typography>
      <Box display="flex" flexDirection="row" mt={2}>
        <Stack width="50%" spacing={3} px={2}>
          <Card
            component={Link}
            to="/Clients"
            sx={{
              width: "100%",
              padding: "20px",
            }}
            elevation={4}
          >
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <img
                className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
                alt=""
                src={"/consultayion-icon.svg"}
              />
              <Box sx={{ flexGrow: 1, padding: 3 }}>
                <Typography variant="h5" color="#255186">
                  {data?.request_type || "No description provided"}
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <CaseInfoComponent data={data} />
          {/* <Notes /> */}
        </Stack>
        <Stack width="50%" spacing={3} px={2}>
          <HeaderComponent />
          <ProfileCardComponent data={data} />
          <MessageSectionComponent data={data} />
          <DocumentsSectionComponent data={data} />
        </Stack>
      </Box>
    </Box>
  );
};

export default RequestDetails;
