import { Box, Typography, Button, Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { ProfileProps } from "interfaces/common";

function checkImage(url: any) {
  const img = new Image();
  img.src = url;
  return img.width !== 0 && img.height !== 0;
}

const OfficeProfile = ({
  type,
  name,
  avatar,
  email,
  id,
  properties,
  Address,
}: ProfileProps) => {
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      bgcolor="#fff"
      minHeight="100vh"
      overflow="hidden"
      textAlign="right"
      fontFamily="Wahran"
      p={2}
    >
      <Typography
        position="relative"
        m={2}
        textAlign={{ xs: "center", md: "right" }}
        fontSize={{ xs: "20px", sm: "25px", md: "29px" }}
      >
        {t("Offices")}
      </Typography>

      <Box
        position="relative"
        width="100%"
        maxWidth="1068px"
        mx="auto"
        p={2}
        borderRadius="8px"
        bgcolor="#F5F7FB"
        border="1px solid #d9e3f1"
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box
            position="relative"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            width={"100%"}
            p={2}
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <Avatar
                src={checkImage(avatar) ? avatar : "/law-firm-logo-1@2x.png"}
                alt=""
                sx={{
                  width: { xs: "80px", md: "112px" },
                  height: { xs: "80px", md: "112px" },
                  marginLeft: 5,
                }}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography
                  fontSize={{ xs: "20px", sm: "25px", md: "36px" }}
                  textAlign="center"
                >
                  {t("office_info")}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2E6CB6",
                  borderRadius: "20px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.25)",
                  width: { xs: "80%", sm: "60%", md: "193px" },
                  "&:hover": {
                    backgroundColor: "#1A4C96", // Change this to your desired hover color
                  },
                }}
              >
                {t("edit_info")}

                <EditIcon sx={{ marginRight: "20px" }} />
              </Button>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            m={1}
            p={2}
            border="1px solid #d9e3f1"
            borderRadius="8px"
            bgcolor="#8DA7C8"
            width={{ xs: "100%", sm: "45%", md: "30%" }}
          >
            <Box display="flex" flexDirection="row-reverse" alignItems="center">
              <Typography
                mt={1}
                fontSize={{ xs: "16px", md: "18px" }}
                color={"#000"}
              >
                {name}
              </Typography>
            </Box>
          </Box>
          <Box
            mt={3}
            mb={10}
            display="flex"
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              m={1}
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
            >
              <Typography>{properties}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              m={1}
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
            >
              <Typography>{email}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              m={1}
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
            >
              <Typography>{id}</Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              m={1}
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
            >
              <Typography>{Address}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OfficeProfile;
