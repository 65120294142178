import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import CustomButton from "../common/CustomButton";
import { FormProps } from "interfaces/common";
import { styled } from "@mui/system";
import { useForm } from "react-hook-form";

const StyledFormControl = styled(FormControl)({
  width: "20%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    borderColor: "#97A4AF",
    "& fieldset": {
      borderColor: "#97A4AF",
    },
    "&:hover fieldset": {
      borderColor: "#97A4AF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#97A4AF",
    },
  },
  "& .MuiSelect-root": {
    padding: "10px 15px",
    color: "#97A4AF",
    fontWeight: 500,
  },
  "& .MuiMenuItem-root": {
    color: "#97A4AF",
    fontWeight: 500,
  },
  "& .MuiSvgIcon-root": {
    color: "#97A4AF",
  },
});

const ClientForm = ({
  type,
  register,
  handleSubmit,
  agent,
  formLoading,
  onFinishHandler,
}: FormProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography fontSize={25} fontWeight={700} color="#11142d">
        {t("add_new_vendor")}
      </Typography>

      <Box mt={2.5} borderRadius="15px" padding="20px" bgcolor="#fcfcfc">
        <form
          style={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
          onSubmit={handleSubmit(onFinishHandler)}
        >
          <Stack direction="column" gap={1} alignItems="flex-start">
            <StyledFormControl variant="outlined">
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#8DA7C8",
                  textAlign: "right",
                }}
              >
                {t("userType")}
              </FormHelperText>
              <Select
                fullWidth
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                }}
                id="userType"
                defaultValue={t("userType")}
                variant="outlined"
                {...register("user_type")}
              >
                <MenuItem value="user">{t("User")}</MenuItem>
                <MenuItem value="lawyer">{t("lawyer")}</MenuItem>
              </Select>
            </StyledFormControl>
          </Stack>
          <Stack direction="row" gap={4} alignItems="flex-end">
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                id="username"
                label={t("enterClientName")}
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                color="info"
                variant="outlined"
                {...register("username", { required: true })}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  direction: "ltr",
                  borderColor: "#8DA7C8",
                }}
                id="email"
                color="info"
                label={t("enterClientEmail")}
                variant="outlined"
                {...register("email")} // No validation for email
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="phone"
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  direction: "ltr",
                  borderColor: "#8DA7C8",
                }}
                color="info"
                type="number"
                variant="outlined"
                label={t("enterClientPhone")}
                {...register("phone")} // No validation for phone
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label={t("idNumber")}
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                type="number"
                id="id_document"
                color="info"
                variant="outlined"
                {...register("id_document")}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={4} alignItems="flex-end">
            <FormControl fullWidth>
              <TextField
                fullWidth
                label={t("enterClientAddress")}
                id="address"
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                color="info"
                variant="outlined"
                {...register("address")} // No validation for address
              />
            </FormControl>
            <StyledFormControl variant="outlined">
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#8DA7C8",
                  textAlign: "right",
                }}
              >
                {t("gender")}
              </FormHelperText>
              <Select
                fullWidth
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                }}
                id="gender"
                defaultValue={t("gender")}
                variant="outlined"
                {...register("gender")}
              >
                <MenuItem value="male">{t("male")}</MenuItem>
                <MenuItem value="female">{t("female")}</MenuItem>
              </Select>
            </StyledFormControl>
          </Stack>
          <Stack direction="row" gap={4} alignItems="flex-end">
            <FormControl fullWidth>
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d",
                  textAlign: "right",
                }}
              >
                {t("password")}
              </FormHelperText>
              <TextField
                fullWidth
                required
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  direction: "ltr",
                  borderColor: "#8DA7C8",
                }}
                type="password"
                id="password"
                color="info"
                variant="outlined"
                {...register("password")}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d",
                  textAlign: "right",
                }}
              >
                {t("confirmPassword")}
              </FormHelperText>
              <TextField
                fullWidth
                required
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  direction: "ltr",
                  borderColor: "#8DA7C8",
                }}
                type="password"
                id="confirmPassword"
                color="info"
                variant="outlined"
                {...register("confirmPassword", {
                  required: true,
                })}
              />
            </FormControl>
          </Stack>
          <CustomButton
            type="submit"
            title={formLoading ? t("submit") : t("submit")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
          />
        </form>
      </Box>
    </Box>
  );
};

export default ClientForm;
