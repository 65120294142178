import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import CustomButton from "../common/CustomButton";
import { FormProps } from "interfaces/common";
import { styled } from "@mui/system";
import AddButton from "components/common/AddButton";
import { useNavigate } from "react-router-dom";

const StyledFormControl = styled(FormControl)(/* Your existing styles */);
interface Note {
  title: string;
  content: string;
}

interface FormData {
  request_type: string;
  location: string;
  plaintiff_name: string;
  defendant_name: string;
  case_type: string;
  national_address: string;
  amount: string;
  uploadedFile: string | null;
  notes: Note[];
}
const LOCAL_STORAGE_KEY = "requestForm";
const RequestForm = ({
  register,
  handleSubmit,
  formLoading,
  onFinishHandler,
}: FormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    request_type: "",
    location: "",
    plaintiff_name: "",
    defendant_name: "",
    case_type: "",
    national_address: "",
    amount: "",
    uploadedFile: null,
    notes: [],
  });
  console.log("🚀 ~ formData:", formData);

  const [openFileModal, setOpenFileModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");

  // Load saved form data when component mounts
  useEffect(() => {
    const savedFormData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedFormData) {
      const parsedData = JSON.parse(savedFormData);
      setFormData(parsedData);

      // Update form fields with saved data
      Object.keys(parsedData).forEach((key) => {
        if (register && key in register) {
          register(key).value = parsedData[key];
        }
      });
    }
  }, []);

  const handleInputChange = (field: string, value: any) => {
    const updatedFormData = { ...formData, [field]: value };
    setFormData(updatedFormData);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setUploadedFile(file);
      handleInputChange("uploadedFile", file.name);
    }
  };

  const handleUploadFileConfirm = () => {
    if (uploadedFile) {
      handleInputChange("uploadedFile", uploadedFile.name);
    }
    setUploadedFile(null);
    setOpenFileModal(false);
  };

  const handleAddNoteConfirm = () => {
    const updatedNotes = [
      ...formData.notes,
      { title: noteTitle, content: noteContent },
    ];
    handleInputChange("notes", updatedNotes);
    setNoteTitle("");
    setNoteContent("");
    setOpenNoteModal(false);
  };

  const handleFormSubmit = (formValues: any) => {
    // Combine the form values with the formData state
    const combinedData = {
      ...formValues,
      ...formData,
    };

    // Call onFinishHandler with the combined data
    onFinishHandler(combinedData);
  };

  // Navigation handlers with data persistence
  const handleNavigateToLawyers = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formData));
    navigate("/Requests/edit/lawyers");
  };

  const handleNavigateToClients = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formData));
    navigate("/Requests/edit");
  };
  // Handlers for file modal
  const handleOpenFileModal = () => setOpenFileModal(true);
  const handleCloseFileModal = () => setOpenFileModal(false);

  // Handlers for note modal
  const handleOpenNoteModal = () => setOpenNoteModal(true);
  const handleCloseNoteModal = () => setOpenNoteModal(false);

  return (
    <Box>
      <Typography fontSize={48} fontWeight={700} color="#11142d">
        {t("add_new_request")}
      </Typography>

      <Box
        mt={2.5}
        borderRadius={4}
        padding="20px"
        bgcolor="#fff"
        width="100%"
        alignSelf="center"
      >
        <form
          style={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Stack
            direction="row"
            gap={10}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              gap={15}
              alignItems="flex-start"
              justifyContent="flex-start"
              width="100%"
            >
              <StyledFormControl variant="outlined">
                <FormHelperText
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#8DA7C8",
                  }}
                >
                  {t("request_type")}
                </FormHelperText>
                <Select
                  fullWidth
                  id="request_type"
                  variant="outlined"
                  value={formData.request_type}
                  onChange={(e) =>
                    handleInputChange("request_type", e.target.value)
                  }
                  sx={{
                    backgroundColor: "#F9FCFF",
                    color: "#8DA7C8",
                    borderColor: "#8DA7C8",
                    width: "200%",
                  }}
                >
                  <MenuItem value="Consolation">استشارة</MenuItem>
                  <MenuItem value="Appointment">حجز موعد</MenuItem>
                  <MenuItem value="New Case">قضية </MenuItem>
                  <MenuItem value="Collection">تحصيل </MenuItem>
                </Select>
              </StyledFormControl>

              <StyledFormControl variant="outlined">
                <FormHelperText
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#8DA7C8",
                    textAlign: "right",
                  }}
                >
                  {t("location")}
                </FormHelperText>
                <TextField
                  fullWidth
                  id="location"
                  value={formData.location}
                  onChange={(e) =>
                    handleInputChange("location", e.target.value)
                  }
                  sx={{
                    backgroundColor: "#F9FCFF",
                    color: "#8DA7C8",
                    borderColor: "#DCECFF",
                  }}
                  InputProps={{
                    style: {
                      borderRadius: 8, // Change this value to your desired border radius
                    },
                  }}
                />
              </StyledFormControl>
            </Stack>
            <Stack direction="row" gap={4} alignItems="flex-end">
              <AddButton
                title={t("uploadDocument")}
                handleClick={handleOpenFileModal}
                backgroundColor="#2E6CB6"
                color="#fcfcfc"
                icon={
                  <img
                    className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
                    alt=""
                    src={"/Group 239.svg"}
                  />
                }
              />
              <AddButton
                title={t("addNote")}
                handleClick={handleOpenNoteModal}
                backgroundColor="#2E6CB6"
                color="#fcfcfc"
                icon={
                  <img
                    className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
                    alt=""
                    src={"/Group 239.svg"}
                  />
                }
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap={4} alignItems="flex-end">
            <StyledFormControl variant="outlined">
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#8DA7C8",
                  textAlign: "right",
                }}
              >
                {t("plaintiffName")}
              </FormHelperText>
              <TextField
                fullWidth
                id="plaintiff_name"
                value={formData.plaintiff_name}
                onChange={(e) =>
                  handleInputChange("plaintiff_name", e.target.value)
                }
                sx={{
                  backgroundColor: "#F9FCFF",
                  color: "#8DA7C8",
                  borderColor: "#DCECFF",
                }}
                InputProps={{
                  style: {
                    borderRadius: 8, // Change this value to your desired border radius
                  },
                }}
              />
            </StyledFormControl>
            <StyledFormControl variant="outlined">
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#8DA7C8",
                  textAlign: "right",
                }}
              >
                {t("defendantName")}
              </FormHelperText>
              <TextField
                fullWidth
                id="defendant_name"
                variant="outlined"
                value={formData.defendant_name}
                onChange={(e) =>
                  handleInputChange("defendant_name", e.target.value)
                }
                sx={{
                  backgroundColor: "#F9FCFF",
                  color: "#8DA7C8",
                  borderColor: "#DCECFF",
                }}
                InputProps={{
                  style: {
                    borderRadius: 8, // Change this value to your desired border radius
                  },
                }}
              />
            </StyledFormControl>
            <StyledFormControl variant="outlined">
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#8DA7C8",
                  textAlign: "right",
                }}
              >
                {t("caseType")}
              </FormHelperText>
              <TextField
                fullWidth
                id="case_type"
                variant="outlined"
                value={formData.case_type}
                onChange={(e) => handleInputChange("case_type", e.target.value)}
                sx={{
                  backgroundColor: "#F9FCFF",
                  color: "#8DA7C8",
                  borderColor: "#DCECFF",
                }}
                InputProps={{
                  style: {
                    borderRadius: 8, // Change this value to your desired border radius
                  },
                }}
              />
            </StyledFormControl>
          </Stack>
          <Stack direction="row" gap={4} alignItems="flex-end">
            <StyledFormControl variant="outlined">
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#8DA7C8",
                  textAlign: "right",
                }}
              >
                {t("plaintiffAdress")}
              </FormHelperText>
              <TextField
                fullWidth
                id="plaintiffAdress"
                variant="outlined"
                value={formData.national_address}
                onChange={(e) =>
                  handleInputChange("national_address", e.target.value)
                }
                sx={{
                  backgroundColor: "#F9FCFF",
                  color: "#8DA7C8",
                  borderColor: "#DCECFF",
                }}
                InputProps={{
                  style: {
                    borderRadius: 8, // Change this value to your desired border radius
                  },
                }}
              />
            </StyledFormControl>
            <StyledFormControl variant="outlined">
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#8DA7C8",
                  textAlign: "right",
                }}
              >
                {t("Amount")}
              </FormHelperText>
              <TextField
                fullWidth
                id="amount"
                variant="outlined"
                value={formData.amount}
                onChange={(e) => handleInputChange("amount", e.target.value)}
                sx={{
                  backgroundColor: "#F9FCFF",
                  color: "#8DA7C8",
                  borderColor: "#DCECFF",
                }}
                InputProps={{
                  style: {
                    borderRadius: 8,
                  },
                }}
              />
            </StyledFormControl>
          </Stack>
          <Stack
            direction="row"
            gap={4}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="column" gap={4} alignItems="flex-end">
              <AddButton
                title={t("selectLawyer")}
                handleClick={handleNavigateToLawyers}
                backgroundColor="#28876B"
                color="#fcfcfc"
                icon={
                  <img
                    className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
                    alt=""
                    src={"/Group 239.svg"}
                  />
                }
              />

              <AddButton
                title={t("selectClient")}
                handleClick={handleNavigateToClients}
                backgroundColor="#2E6CB6"
                color="#fcfcfc"
                icon={
                  <img
                    className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
                    alt=""
                    src={"/Group 239.svg"}
                  />
                }
              />
            </Stack>
            <CustomButton
              type="submit"
              title={formLoading ? t("add_new_request") : t("add_new_request")}
              backgroundColor="#2E6CB6"
              color="#fcfcfc"
              style={{
                width: "35%",
                minHeight: "60px",
                fontSize: 24,
                paddingY: 2,
              }}
            />
          </Stack>
        </form>
      </Box>
      <Dialog open={openFileModal} onClose={handleCloseFileModal}>
        <DialogTitle>{t("uploadDocument")}</DialogTitle>
        <DialogContent
          sx={{
            p: 20,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <input
            type="file"
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx,.png,.jpg"
          />
          {uploadedFile && (
            <Typography mt={2}>
              {t("fileSelected")}: {uploadedFile.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileModal} color="secondary">
            {t("cancel")}
          </Button>
          <Button onClick={handleUploadFileConfirm} color="primary">
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Modal for adding notes */}
      <Dialog open={openNoteModal} onClose={handleCloseNoteModal}>
        <DialogTitle>{t("addNote")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("noteTitle")}
            fullWidth
            value={noteTitle}
            onChange={(e) => setNoteTitle(e.target.value)}
            margin="normal"
            sx={{
              direction: "ltr",
            }}
          />
          <TextField
            label={t("noteContent")}
            fullWidth
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            sx={{
              direction: "ltr",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoteModal} color="secondary">
            {t("cancel")}
          </Button>
          <Button onClick={handleAddNoteConfirm} color="primary">
            {t("addNote")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RequestForm;
