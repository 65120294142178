import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { FormProps } from "interfaces/common";
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";

const Form = ({
  register,
  handleSubmit,
  onFinishHandler,
  formLoading,
  agentData,
}: FormProps) => {
  console.log("🚀 ~ agentData:", agentData);
  const { t } = useTranslation();

  return (
    <Box>
      <Typography fontSize={25} fontWeight={700} color="#11142d">
        {t("update_new_vendor")}
      </Typography>

      <Box mt={2.5} borderRadius="15px" padding="20px" bgcolor="#fcfcfc">
        <form
          style={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
          onSubmit={handleSubmit(onFinishHandler)}
        >
          <Stack direction="row" gap={4} alignItems="flex-end">
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="username"
                label={t("enterClientName")}
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                color="info"
                variant="outlined"
                defaultValue={agentData.username}
                {...register("username")}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                fullWidth
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                id="email"
                color="info"
                label={t("enterClientEmail")}
                variant="outlined"
                defaultValue={agentData.email}
                {...register("email")} // No validation for email
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="phone"
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                color="info"
                type="number"
                variant="outlined"
                label={t("enterClientPhone")}
                defaultValue={agentData.phone}
                {...register("phone")} // No validation for phone
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label={t("idNumber")}
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                type="number"
                id="id_document"
                color="info"
                variant="outlined"
                defaultValue={agentData.id_document}
                {...register("id_document")}
              />
            </FormControl>
          </Stack>
          {/* <Stack direction="row" gap={4} alignItems="flex-end">
            <FormControl fullWidth>
              <TextField
                fullWidth
                label={t("enterClientAddress")}
                id="address"
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                }}
                color="info"
                variant="outlined"
                defaultValue={agentData.address}
                {...register("address")} // No validation for address
              />
            </FormControl>
            <Stack direction="row" gap={4} alignItems="flex-end">
              <FormControl fullWidth>
                <FormHelperText
                  sx={{
                    fontWeight: 500,
                    margin: "10px 0",
                    fontSize: 16,
                    color: "#11142d",
                    textAlign: "right",
                  }}
                >
                  {t("dobDay")}
                </FormHelperText>
                <TextField
                  fullWidth
                  id="day"
                  label="00"
                  type="number"
                  sx={{
                    backgroundColor: "rgba(141, 167, 200, 0.2)",
                    color: "#8DA7C8",
                    borderColor: "#8DA7C8",
                  }}
                  color="info"
                  variant="outlined"
                  {...register("day")}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormHelperText
                  sx={{
                    fontWeight: 500,
                    margin: "10px 0",
                    fontSize: 16,
                    color: "#11142d",
                    textAlign: "right",
                  }}
                >
                  {t("dobMonth")}
                </FormHelperText>
                <TextField
                  fullWidth
                  type="number"
                  sx={{
                    backgroundColor: "rgba(141, 167, 200, 0.2)",
                    color: "#8DA7C8",
                    borderColor: "#8DA7C8",
                  }}
                  label="00"
                  id="month"
                  color="info"
                  variant="outlined"
                  {...register("month")}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormHelperText
                  sx={{
                    fontWeight: 500,
                    margin: "10px 0",
                    fontSize: 16,
                    color: "#11142d",
                    textAlign: "right",
                  }}
                >
                  {t("dobYear")}
                </FormHelperText>
                <TextField
                  fullWidth
                  label="0000"
                  type="number"
                  sx={{
                    backgroundColor: "rgba(141, 167, 200, 0.2)",
                    color: "#8DA7C8",
                    borderColor: "#8DA7C8",
                  }}
                  id="Year"
                  color="info"
                  variant="outlined"
                  {...register("Year")}
                />
              </FormControl>
            </Stack>
          </Stack> */}
          <Stack direction="row" gap={4} alignItems="flex-end">
            <FormControl fullWidth>
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d",
                  textAlign: "right",
                }}
              >
                {t("password")}
              </FormHelperText>
              <TextField
                fullWidth
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                type="password"
                id="password"
                color="info"
                variant="outlined"
                {...register("password")}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormHelperText
                sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d",
                  textAlign: "right",
                }}
              >
                {t("confirmPassword")}
              </FormHelperText>
              <TextField
                fullWidth
                sx={{
                  backgroundColor: "rgba(141, 167, 200, 0.2)",
                  color: "#8DA7C8",
                  borderColor: "#8DA7C8",
                  direction: "ltr",
                }}
                type="password"
                id="confirmPassword"
                color="info"
                variant="outlined"
                {...register("confirmPassword")}
              />
            </FormControl>
          </Stack>
          <CustomButton
            type="submit"
            title={t("update_new_vendor")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
          />
        </form>
      </Box>
    </Box>
  );
};

export default Form;
