import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { AddButton, CustomButton } from "components";
import { useEffect, useState } from "react";
import axios from "../../axiosConfig";
import { ClientProps } from "interfaces/agent";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  InputBase,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const Lawyers = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [agentToDelete, setAgentToDelete] = useState<ClientProps>();

  const [data, setData] = useState<ClientProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      console.log("🚀 ~ Lawyers ~ isError:");
      try {
        const response = await axios.get("admin/get_users");
        console.log("🚀 ~ fetchData ~ response:", response);
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsError(true);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) return <div>loading...</div>;
  if (isError) return <div>error...</div>;
  const allUsers = data ?? [];

  const filteredUsers = allUsers.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const onlyLawyers = filteredUsers.filter((user) => {
    return user.type === "lawyer";
  });

  const handleOpen = (agent: any) => {
    console.log("🚀 ~ handleOpen ~ agent:", agent);
    setAgentToDelete(agent);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setAgentToDelete(null);
  };

  const handleDelete = async () => {
    console.log("🚀 ~ handleDelete ~ agentToDelete:", agentToDelete);

    if (agentToDelete) {
      // const entityType = agentToDelete.type === "lawyer" ? "lawyer" : "user";
      const endpoint = `admin/delete_user/${agentToDelete.id}`;

      try {
        const response = await axios.delete(endpoint);
        if (response.status === 200) {
          // Handle success, e.g., refresh list or show success message
          console.log("Deleted successfully");
        } else {
          // Handle error
          console.log("Delete failed");
        }
      } catch (error) {
        console.error("Error deleting:", error);
      } finally {
        handleClose();
      }
    }
  };

  const SearchBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E7FF", // Light blue border color
    borderRadius: "8px", // Rounded corners
    padding: theme.spacing(0.5, 1),
    backgroundColor: "#F8FAFF",
    width: "60%",
  }));

  const CustomInputBase = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
    "& input::placeholder": {
      textAlign: "right", // Right-align the placeholder text for Arabic
    },
  }));

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography id="delete-modal-title" variant="h6" component="h2">
            {t("Are you sure you want to delete this agent?")}
          </Typography>

          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
              m: 4,
            }}
          >
            <Box>
              <CustomButton
                type="submit"
                title={t("confirm")}
                backgroundColor="#C84630"
                color="#fcfcfc"
                handleClick={handleDelete}
              />
            </Box>
            <Box>
              <CustomButton
                type="button"
                title={t("cancel")}
                backgroundColor="#ccc"
                color="#000"
                handleClick={handleClose}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Typography
        fontSize={25}
        fontWeight={700}
        color="#11142d"
        fontFamily={"Wahran"}
      >
        {t("lawyers")}
      </Typography>
      <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
        <SearchBox>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <CustomInputBase
            placeholder={t("search_placeholder")}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
        <AddButton
          title={t("add_new_vendor")}
          handleClick={() => navigate("/clients/create")}
          backgroundColor="#2E6CB6"
          color="#fcfcfc"
          icon={
            <img
              className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
              alt=""
              src={"/Group 239.svg"}
            />
          }
        />
      </Box>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow style={{ background: "rgba(220, 236, 255, 0.30)" }}>
              <TableCell> </TableCell>
              <TableCell>{t("username")}</TableCell>
              <TableCell>{t("user_type")}</TableCell>
              {/* <TableCell>{t("account_creation_date")}</TableCell> */}
              <TableCell>{t("email")}</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {onlyLawyers.map((agent) => (
              <TableRow key={agent.id}>
                <TableCell>
                  {agent.type === "user" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/ssasdsada.svg"}
                    />
                  ) : (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/lawyer-icon.svg"}
                    />
                  )}
                </TableCell>
                <TableCell>{agent.username}</TableCell>
                <TableCell>
                  {agent.type === "user" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/client.svg"}
                    />
                  ) : (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/lawyer.svg"}
                    />
                  )}
                </TableCell>
                {/* <TableCell>{agent.date ? agent.date : "02-05-2024"}</TableCell> */}
                <TableCell>{agent.email}</TableCell>
                <TableCell>
                  <Link
                    to="/lawyers/edit"
                    state={{ agent }}
                    style={{ textDecoration: "none" }}
                  >
                    {/* <IconButton onClick={() => handleOpen(agent)}> */}
                    <IconButton>
                      <EditIcon style={{ color: "#8DA7C8" }} />
                    </IconButton>
                  </Link>
                  <IconButton onClick={() => handleOpen(agent)}>
                    <DeleteIcon style={{ color: "#8DA7C8" }} />
                  </IconButton>
                  <Link
                    to="/lawyers/show"
                    state={{ agent }}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton>
                      <InfoIcon style={{ color: "#8DA7C8" }} />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Lawyers;
