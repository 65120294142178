import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AddButton, CustomButton } from "components";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputBase,
  Pagination,
  PaginationItem,
  Modal,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import axios from "../../axiosConfig";

interface RequestData {
  case_id: number;
  plaintiff_name: string | null;
  request_type: string | null;
  created_at: string | null;
  status: string | null;
  user_id: number;
  type: string | null;
  // Add o: string | null;ther relevant fields as needed
}

const Requests = () => {
  const [requests, setRequests] = useState<RequestData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState<RequestData>();
  console.log("🚀 ~ Requests ~ resquests:", requests);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get("admin/get_requests")
      .then((response) => {
        setRequests(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
        setLoading(false);
      });
  }, []);

  const allRequests = requests ?? [];

  const handleOpen = (request: any) => {
    console.log("🚀 ~ handleOpen ~ request:", request);
    setRequestToDelete(request);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setrequestToDelete(null);
  };

  const handleDelete = () => {
    console.log("🚀 ~ handleDelete ~ requestToDelete:", requestToDelete);

    if (requestToDelete) {
      const entityType =
        requestToDelete.request_type === "lawyer" ? "lawyer" : "user";
      const endpoint = `/api/superadmin/delete_entity/${entityType}/${requestToDelete.case_id}`;

      fetch(endpoint, {
        method: "DELETE",
      })
        .then((response) => {
          if (response.ok) {
            // Handle success, e.g., refresh list or show success message
            console.log("Deleted successfully");
          } else {
            // Handle error
            console.log("Delete failed");
          }
          handleClose();
        })
        .catch((error) => {
          console.error("Error deleting:", error);
          handleClose();
        });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setTabValue(newValue);
  //   setCurrentPage(1); // Reset to first page when tab changes
  // };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  // const filteredRequests = allRequests.filter((request) =>
  //   request.plaintiff_name.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // const tabFilteredRequests = filteredRequests.filter((request) => {
  //   if (tabValue === 1) return request.request_type === "user";
  //   if (tabValue === 2) return request.request_type === "lawyer";
  //   return true;
  // });

  const paginatedRequests = allRequests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Custom styled component for the search box
  const SearchBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E7FF", // Light blue border color
    borderRadius: "8px", // Rounded corners
    padding: theme.spacing(0.5, 1),
    backgroundColor: "#F8FAFF",
    width: "60%",
  }));

  const CustomInputBase = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
    "& input::placeholder": {
      textAlign: "right", // Right-align the placeholder text for Arabic
    },
  }));
  // Custom styled PaginationItem for the selected page
  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "#2E6CB6", // Background color for the selected page
      color: "#FFFFFF", // Text color for the selected page
      borderRadius: 4,
    },
  }));
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography id="delete-modal-title" variant="h6" component="h2">
            {t("Are you sure you want to delete this request?")}
          </Typography>

          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
              m: 4,
            }}
          >
            <Box>
              <CustomButton
                type="submit"
                title={t("confirm")}
                backgroundColor="#C84630"
                color="#fcfcfc"
                handleClick={handleDelete}
              />
            </Box>
            <Box>
              <CustomButton
                type="button"
                title={t("cancel")}
                backgroundColor="#ccc"
                color="#000"
                handleClick={handleClose}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Typography
        fontSize={25}
        fontWeight={700}
        color="#11142d"
        fontFamily={"Wahran"}
      >
        {t("Requests")}
      </Typography>
      <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
        <SearchBox>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <CustomInputBase
            placeholder={t("search_placeholder")}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
        <AddButton
          title={t("add_new_request")}
          handleClick={() => navigate("/Requests/create")}
          backgroundColor="#2E6CB6"
          color="#fcfcfc"
          icon={
            <img
              className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
              alt=""
              src={"/Group 239.svg"}
            />
          }
        />
      </Box>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow style={{ background: "rgba(220, 236, 255, 0.30)" }}>
              <TableCell> </TableCell>
              <TableCell>{t("username")}</TableCell>
              <TableCell>{t("user_type")}</TableCell>
              <TableCell>{t("request_type")}</TableCell>
              <TableCell>{t("account_creation_date")}</TableCell>
              <TableCell>{t("status")}</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRequests.map((request) => (
              <TableRow key={request.case_id}>
                <TableCell>
                  <img alt="" src={"consulting.png"} />
                </TableCell>

                <TableCell>{request.plaintiff_name}</TableCell>
                <TableCell>
                  {request.type === "user" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/client.svg"}
                    />
                  ) : (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/lawyer.svg"}
                    />
                  )}
                </TableCell>

                <TableCell>
                  {request.request_type === "consulting" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/consultingrectangle.svg"}
                    />
                  ) : request.request_type === "appointment" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/appointmentrectangle.svg"}
                    />
                  ) : request.request_type === "case" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/caserectangle.svg"}
                    />
                  ) : request.request_type === "collecting" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/collectingrectangle.svg"}
                    />
                  ) : (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/enforcementrectangle.svg"}
                    />
                  )}
                </TableCell>

                <TableCell>
                  {request.created_at
                    ? new Date(request.created_at).toLocaleDateString("ar-GB") // formats to "dd-mm-yyyy"
                    : "02-05-2024"}
                </TableCell>
                <TableCell>{request.status}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpen(request)}>
                    <DeleteIcon style={{ color: "#8DA7C8" }} />
                  </IconButton>
                  <Link
                    to="/requests/show"
                    state={{ request }}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton>
                      <InfoIcon style={{ color: "#8DA7C8" }} />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(allRequests.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="standard"
          renderItem={(item) => (
            <CustomPaginationItem
              components={{
                previous: () => (
                  <img
                    src="/keyboard_double_arrow_right.svg"
                    alt="Previous"
                    style={{ width: "24px", height: "24px" }}
                  />
                ),
                next: () => (
                  <img
                    src="/keyboard_double_arrow_left.svg"
                    alt="Next"
                    style={{ width: "24px", height: "24px" }}
                  />
                ),
              }}
              {...item}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default Requests;
