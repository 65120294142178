import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { AddButton, CustomButton } from "components";
import { useState } from "react";
import { ClientProps } from "interfaces/agent";
import SearchIcon from "@mui/icons-material/Search";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Button,
  IconButton,
  InputBase,
  Modal,
  Paper,
  TableContainer,
} from "@mui/material";

const legalLibrary = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [agentToDelete, setAgentToDelete] = useState<ClientProps>();

  const [data, setData] = useState<ClientProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  if (isLoading) return <div>loading...</div>;
  if (isError) return <div>error...</div>;
  const allUsers = data ?? [];

  const SearchBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E7FF", // Light blue border color
    borderRadius: "8px", // Rounded corners
    padding: theme.spacing(0.5, 1),
    backgroundColor: "#F8FAFF",
    width: "60%",
  }));

  const CustomInputBase = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
    "& input::placeholder": {
      textAlign: "right", // Right-align the placeholder text for Arabic
    },
  }));
  const AddButtonContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    backgroundColor: "#F8FAFF",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#2979FF", // blue color as per your image
    color: "#fff",
    padding: theme.spacing(1, 3),
    textTransform: "none",
    borderRadius: "16px",
    "&:hover": {
      backgroundColor: "#1565c0",
    },
  }));

  const StyledTitleButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#2d3748", // dark color as per your image
    color: "#fff",
    padding: theme.spacing(1, 8),
    textTransform: "none",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#000",
    },
  }));

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography id="delete-modal-title" variant="h6" component="h2">
            {t("Are you sure you want to delete this agent?")}
          </Typography>

          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
              m: 4,
            }}
          >
            <Box>
              <CustomButton
                type="submit"
                title={t("confirm")}
                backgroundColor="#C84630"
                color="#fcfcfc"
                // handleClick={handleDelete}
              />
            </Box>
            <Box>
              <CustomButton
                type="button"
                title={t("cancel")}
                backgroundColor="#ccc"
                color="#000"
                // handleClick={handleClose}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Typography
        fontSize={25}
        fontWeight={700}
        color="#11142d"
        fontFamily={"Wahran"}
      >
        {t("legal_library")}
      </Typography>
      <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
        <SearchBox>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <CustomInputBase
            placeholder={t("search_placeholder")}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
        <AddButton
          title={t("add_docx")}
          // handleClick={() => navigate("/clients/create")}
          backgroundColor="#d1b76c"
          color="#fcfcfc"
          icon={
            <img
              className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
              alt=""
              src={"/Group 239.svg"}
            />
          }
        />
      </Box>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Box sx={{ display: "flex", gap: 2, m: 2 }}>
          <AddButtonContainer sx={{ flex: 1 }}>
            <StyledTitleButton>{t("folder_title")}</StyledTitleButton>
            <AddButton
              title={t("add_docx")}
              // handleClick={() => navigate("/clients/create")}
              backgroundColor="#2e6cb6"
              color="#fcfcfc"
              icon={
                <img
                  className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
                  alt=""
                  src={"/Group 239.svg"}
                />
              }
            />
          </AddButtonContainer>

          <AddButtonContainer sx={{ flex: 1 }}>
            <StyledTitleButton>{t("folder_title")}</StyledTitleButton>
            <AddButton
              title={t("add_docx")}
              // handleClick={() => navigate("/clients/create")}
              backgroundColor="#2e6cb6"
              color="#fcfcfc"
              icon={
                <img
                  className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
                  alt=""
                  src={"/Group 239.svg"}
                />
              }
            />
          </AddButtonContainer>
        </Box>
      </TableContainer>
    </Box>
  );
};

export default legalLibrary;
