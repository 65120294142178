import React from "react";
import { useGetIdentity, useTitle } from "@refinedev/core";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/material/styles";

export const Header: React.FC = () => {
  // const { data: user } = useGetIdentity({
  //   v3LegacyAuthProviderCompatible: true,
  // });
  // const showUserInfo = user && (user.name || user.avatar);
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  // Inline styles
  const avatarStyle = {
    width: "40px",
    height: "40px",
    marginLeft: "10px",
  };

  const userNameStyle = {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2e6cb6", // Use your main color
    // textAlign: "center"
  };

  const userEmailStyle = {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000", // Use your normal color
  };

  const verifiedIconStyle = {
    fontSize: "18px",
    color: "#1c3c5d", // Dark blue color for verified icon
  };
  const containerStyle = {
    border: "1px solid #dcecff", // Gold color for the border
    borderRadius: "40px",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Adding shadow effect
    marginTop: "10px",
  };
  return (
    <AppBar
      color="default"
      position="sticky"
      elevation={0}
      sx={{ background: "#F9FCFF" }}
    >
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* {showUserInfo && (
            <Stack direction="row" gap="16px" alignItems="center">
              {user.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
              {user.name && (
                <Typography variant="subtitle2">{user?.name}</Typography>
              )}
            </Stack>
          )} */}
          <div style={containerStyle}>
            <Avatar src={user.avatar} alt={user.name} style={avatarStyle} />
            <Stack direction="row" spacing={2} alignItems="center">
              <Stack>
                <Typography
                  variant="subtitle2"
                  style={userNameStyle}
                  textAlign={"center"}
                >
                  {user?.username}
                </Typography>
                <Typography variant="body2" style={userEmailStyle}>
                  {user?.email}
                </Typography>
              </Stack>
              {/* <VerifiedIcon style={verifiedIconStyle} />{" "} */}
              {/* Replace with your verified icon */}
            </Stack>
          </div>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
