import React, { useEffect } from "react";
import { useLogin } from "@refinedev/core";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { yariga } from "../assets";

interface LoginFormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
  password: Yup.string().required("Required"),
});

export const Login: React.FC = () => {
  const { mutate: login } = useLogin<{ email: string; password: string }>({
    v3LegacyAuthProviderCompatible: true,
  });

  const { t, i18n, ready } = useTranslation();
  console.log("🚀 ~ i18n:", i18n.language);

  const handleSubmit = (values: LoginFormValues) => {
    login({ email: values.email, password: values.password });
  };
  if (!ready) {
    return <div>Loading...</div>; // Wait until i18next is ready
  }
  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "ar" : "ar";
    i18n.changeLanguage(newLang);
  };

  useEffect(() => {
   
      toggleLanguage()
  }, []);
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: "#FCFCFC",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box mt={4} width="100%">
            <Box
              sx={{
                p: 3,
                boxShadow: 3,
                borderRadius: 2,
                border: "1px solid #ddd",
                backgroundColor: "white",
              }}
            >
              <Box
                mt={4}
                width="100%"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <div>
                  <img src={yariga} alt="Yariga Logo" width="340px" />
                </div>
                <Typography fontSize={24} fontWeight={"bold"} color="#243447">
                  {t("Log In")}
                </Typography>
              </Box>

              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Box mb={2}>
                      <Field
                        as={TextField}
                        name="email"
                        label={t("email")}
                        fullWidth
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        sx={{
                          direction: i18n.language === "ar" ? "rtl" : "ltr",
                        }}
                      />
                    </Box>
                    <Box mb={2}>
                      <Field
                        as={TextField}
                        name="password"
                        label={t("password")}
                        type="password"
                        fullWidth
                        error={touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        sx={{
                          direction: i18n.language === "ar" ? "rtl" : "ltr",
                        }}
                      />
                    </Box>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#2e6cb6",
                        "&:hover": { backgroundColor: "#1e4e8c" },
                      }}
                      fullWidth
                    >
                      {t("Login")}
                    </Button>
                  </Form>
                )}
              </Formik>
              {/* <Button
                onClick={toggleLanguage}
                sx={{
                  mt: 2,
                  color: "#2e6cb6",
                }}
                fullWidth
              >
                {t("Switch Language")}
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box
        component="footer"
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          textAlign: "center",
          padding: "1rem",
          backgroundColor: "#233449",
          borderTop: "1px solid #ddd",
        }}
      >
        <Typography variant="body2" color="#fff">
          {t("footerText")}
        </Typography>
      </Box>
    </Box>
  );
};
