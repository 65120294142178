import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { LawyersCard, NotificationsCard, OffecesCard } from "components";
import axios from "axiosConfig";
import { Button } from "@mui/material";

// Define the type for the fetched data
interface UserData {
  id: number;
  total_offices: string;
  // Add other relevant fields as needed
}

const adminMain: React.FC = () => {
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const user = localStorage.getItem("user");
  const { t, i18n, ready } = useTranslation();
  console.log("🚀 ~ i18n:", i18n.language);


  if (!ready) {
    return <div>Loading...</div>; // Wait until i18next is ready
  }
  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "ar" : "ar";
    i18n.changeLanguage(newLang);
  };

  // useEffect(() => {
  //   fetch("https://elavvocato.com/api/superadmin/stats/all", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       console.log("🚀 ~ .then ~ response:", response);
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("🚀 ~ .then ~ data:", data);
  //       setUsers(data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("🚀 ~ useEffect ~ error:", error);
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get("superadmin/stats/all", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
        console.error("Error fetching data:", error);
        setLoading(false);
      });
      toggleLanguage()
  }, []);

  return (
    <Box
      mt="20px"
      sx={{ display: "flex", flexWrap: "wrap", gap: 12, width: "100%" }}
    >
      <Typography
        variant="h4"
        sx={{
          position: "absolute",
          top: 90,
          marginRight: 10,
          color: "#243447",
          zIndex: 100,
          fontSize: 48,
        }}
      >
        {t("Main")}
      </Typography>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 6,
            marginTop: 5,
            marginLeft: 15,
            paddingX: 10,
            paddingY: 10,
          }}
        >
              {/* <Button
                onClick={toggleLanguage}
                sx={{
                  mt: 2,
                  color: "#2e6cb6",
                }}
                fullWidth
              >
                {t("Switch Language")}
              </Button> */}
          <Stack
            gap={6}
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            zIndex={100}
          >
            {/* <Card
              component={Link}
              to="/Clients"
              sx={{
                // maxWidth: "330px",
                width: "25%",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <OffecesCard
                prop={t("agencies")}
                icroundNotifications="/carbon_enterprise.svg"
                prop1={"5"}
              />
            </Card> */}
            <Card
              component={Link}
              to="show"
              sx={{
                // maxWidth: "330px",
                width: "25%",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("legal_library")}
                icroundNotifications="/mdi_legal.svg"
                prop1=""
              />
            </Card>
            <Card
              component={Link}
              to="/Lawyers"
              sx={{
                // maxWidth: "330px",
                width: "25%",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("Lawyers")}
                icroundNotifications="/user-icon5.svg"
                prop1={"20"}
              />
            </Card>
            <Card
              component={Link}
              to="/clients"
              sx={{
                // maxWidth: "330px",
                width: "25%",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <NotificationsCard
                prop={t("Clients")}
                icroundNotifications="/user-icon4.svg"
                prop1={"50"}
              />
            </Card>
          </Stack>
          <Stack
            gap={6}
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            zIndex={100}
          >
            <Card
              component={Link}
              to="/Requests"
              sx={{
                // maxWidth: "330px",
                width: "25%",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("Requests")}
                icroundNotifications="/clarity_list-solid.svg"
                prop1="20"
              />
            </Card>

            <Card
              component={Link}
              to="/Cases"
              sx={{
                // maxWidth: "330px",
                width: "25%",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("Cases")}
                icroundNotifications="/carbon_cases.svg"
                prop1={"10"}
              />
            </Card>
            <Card
              component={Link}
              to="/Notifications"
              sx={{
                // maxWidth: "330px",
                width: "25%",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("Notifications")}
                icroundNotifications="/icroundnotifications2.svg"
                prop1="15"
              />
            </Card>
          </Stack>
          <Stack
            gap={6}
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            zIndex={100}
          >
            <Card
              component={Link}
              to="/reports"
              sx={{
                // maxWidth: "330px",
                width: "25%",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("Reports")}
                icroundNotifications="/carbon_report.svg"
                prop1=""
              />
            </Card>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default adminMain;
