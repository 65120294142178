import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Card,
  CardContent,
  styled,
  InputBase,
  PaginationItem,
  Avatar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { OffecesCard } from "components";
import { useTranslation } from "react-i18next";
import axios from "../axiosConfig";

interface UserData {
  Address: string;
  admin_email: string;
  created_at: string;
  admin_username: string;
  id: number;
  Name: string;
  ID: number;
}
interface ReportData {
  total_lawyers: any;
  total_offices: number;
  total_users: number;
  total_requests: number;
}
const Inventory = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserData[]>([]);
  console.log("🚀 ~ Inventory ~ users:", users);
  const [report, setReport] = useState<ReportData>();
  const [officeReport, setOfficeReport] = useState<ReportData | null>(null);
  console.log("🚀 ~ Inventory ~ officeReport:", officeReport);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/superadmin/stats/all", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setReport(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // Handle office-specific report fetching
  const handleReportClick = async (officeId: number) => {
    try {
      const response = await axios.get(`/superadmin/stats/office/${officeId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setOfficeReport(response.data);
    } catch (error) {
      console.error("Error fetching office report:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/superadmin/all_offices", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };
  const data = {
    headerCards: [
      {
        title: "العملاء",
        count: report?.total_users,
        icon: "/user-icon4.svg",
        to: "/vendors",
      },
      {
        title: "المحامين",
        count: report?.total_lawyers,
        icon: "/user-icon5.svg",
        to: "/vendors",
      },
      {
        title: "المكاتب",
        count: report?.total_offices,
        icon: "/hugeicons_office.svg",
        to: "/clients",
      },
    ],
    progressBars: [
      {
        title: "عدد المحامين التابعين للمكتب",
        count: officeReport?.total_lawyers,
        progress: 0.3,
        to: "/category",
      },
      {
        title: "عدد العملاء التابعين للمكتب",
        count: officeReport?.total_users,
        progress: 0.6,
        to: "/category",
      },
      {
        title: "عدد الطلبات التي يستقبلها المكتب",
        count: officeReport?.total_requests,
        progress: 0.9,
        to: "/category",
      },
    ],
    tableData: Array(10).fill({ office: "مكتب الثقة", date: "01-05-2024" }),
  };
  const filteredAgents = users.filter((agent) =>
    agent?.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Custom styled PaginationItem for the selected page
  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "#2E6CB6", // Background color for the selected page
      color: "#FFFFFF", // Text color for the selected page
      borderRadius: 4,
    },
  }));
  const formatArabicDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("ar-EG", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  return (
    <Box>
      <Box mt="20px" sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
        {data.headerCards.map((card, index) => (
          <Card
            key={index}
            component={Link}
            to={card.to}
            sx={{
              maxWidth: "330px",
              width: "300px", // Ensure the width is set
              height: "auto", // You can also set a specific height if needed
              padding: "20px", // Adjust the padding if needed
              "&:hover": {
                boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
              },
              cursor: "pointer",
            }}
            elevation={4}
          >
            <OffecesCard
              prop={card.title}
              icroundNotifications={card.icon}
              prop1={card?.count}
            />
          </Card>
        ))}
      </Box>
      <Box display="flex" p={2} height="100vh">
        <Box width="50%" p={2} mt="20px">
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>

                  <TableCell>المكتب</TableCell>
                  <TableCell>تاريخ الإنشاء</TableCell>
                  <TableCell></TableCell>

                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Avatar src={"/lawfirm-logo.svg"} alt={row.Name} />
                    </TableCell>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>
                      <td>{formatArabicDate(row.created_at)}</td>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="text"
                        color="info"
                        onClick={() => handleReportClick(row.ID)}
                      >
                        التقارير
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="info">
                        طباعة
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={Math.ceil(filteredAgents.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="standard"
              renderItem={(item) => (
                <CustomPaginationItem
                  components={{
                    previous: () => (
                      <img
                        src="/keyboard_double_arrow_right.svg"
                        alt="Previous"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                    next: () => (
                      <img
                        src="/keyboard_double_arrow_left.svg"
                        alt="Next"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  }}
                  {...item}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          width="50%"
          p={5}
          mt="20px"
          display="flex"
          flexDirection={"column"}
        >
          {data.progressBars.map((bar, index) => (
            <Card
              key={index}
              component={Link}
              to={bar.to}
              sx={{
                padding: "20px", // Adjust the padding if needed
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                margin: "10px",
                cursor: "pointer",
              }}
              elevation={4}
            >
              <Typography variant="h6">{bar.title}</Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="h4" sx={{ minWidth: "50px" }}>
                  {bar.count}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={bar.progress * 100}
                  sx={{ flex: 1, ml: 2, height: "10px", borderRadius: "5px" }}
                />
              </Stack>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Inventory;
