import { useOne } from "@refinedev/core";
import { useLocation, useParams } from "react-router-dom";

import { Profile } from "components";

const VendorProfile = () => {
  const location = useLocation();
  const data = location.state?.agent;
  console.log("🚀 ~ VendorProfile ~ data:", data);

  const dummyProfile = {
    username: "Dummy Vendor",
    email: "dummy@vendor.com",
    avatar: null,
    allTransactions: [],
    id: "dummy-id",
    type: "lawyer",
  };

  const myProfile = data ?? dummyProfile;

  // if (isLoading) return <div>loading...</div>;
  // if (isError) return <div>error...</div>;

  return (
    <Profile
      type={myProfile.type}
      name={myProfile.username}
      email={myProfile.email}
      avatar={myProfile.avatar}
      properties={null}
      id={myProfile.phone?.toString() ?? ""}
      Address={undefined}
    />
  );
};

export default VendorProfile;
