import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AddButton, CustomButton } from "components";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputBase,
  Pagination,
  PaginationItem,
  Modal,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import axios from "../axiosConfig";

interface UserData {
  Address: string;
  admin_email: string;
  created_at: string;
  admin_username: string;
  id: number;
  ID: number;
  type: string;
  Name: string;
}
interface DeleteResponse {
  // Define the expected structure of the delete response if you know it
  // This is just an example; adjust it based on the actual response structure
  success: boolean;
  message: string;
}
const office = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState<UserData>();
  const handleOpen = (agent: any) => {
    console.log("🚀 ~ handleOpen ~ agent:", agent);
    setAgentToDelete(agent);
    setOpen(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/superadmin/all_offices", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [open]);
  const handleClose = () => {
    setOpen(false);
    // setAgentToDelete(null);
  };
  const handleDelete = () => {
    if (agentToDelete) {
      axios
        .delete(`/superadmin/delete_office/${agentToDelete.ID}`)
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response.data);
          handleClose();
        })
        .catch((error: any) => {
          console.error("Error deleting:", error);
          handleClose();
        });
    }
  };
  const navigate = useNavigate();
  const allAgents = users ?? [];
  // const allAgents = dummyData;
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  // Function to change the date format
  const changeDateFormat = (dateString: string | number | Date) => {
    // Create a new Date object from the original date string
    const date = new Date(dateString);

    // Get the day, month, and year from the Date object
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();

    // Format the date in "dd-mm-yyyy" format
    return `${day}-${month}-${year}`;
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const filteredAgents = allAgents?.filter(
    (agent) =>
      agent?.Name &&
      agent?.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // const tabFilteredAgents = filteredAgents.filter((agent) => {
  //   if (tabValue === 1) return agent.type === "user";
  //   if (tabValue === 2) return agent.type === "lawyer";
  //   return true;
  // });

  const paginatedAgents = filteredAgents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Custom styled component for the search box
  const SearchBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E7FF", // Light blue border color
    borderRadius: "8px", // Rounded corners
    padding: theme.spacing(0.5, 1),
    backgroundColor: "#F8FAFF",
    width: "60%",
  }));

  const CustomInputBase = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
    "& input::placeholder": {
      textAlign: "right", // Right-align the placeholder text for Arabic
    },
  }));
  // Custom styled PaginationItem for the selected page
  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "#2E6CB6", // Background color for the selected page
      color: "#FFFFFF", // Text color for the selected page
      borderRadius: 4,
    },
  }));

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography id="delete-modal-title" variant="h6" component="h2">
            {t("Are you sure you want to delete this agent?")}
          </Typography>

          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
              m: 4,
            }}
          >
            <Box>
              <CustomButton
                type="submit"
                title={t("confirm")}
                backgroundColor="#C84630"
                color="#fcfcfc"
                handleClick={handleDelete}
              />
            </Box>
            <Box>
              <CustomButton
                type="button"
                title={t("cancel")}
                backgroundColor="#ccc"
                color="#000"
                handleClick={handleClose}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      <Typography
        fontSize={25}
        fontWeight={700}
        color="#11142d"
        fontFamily={"Wahran"}
      >
        {t("Offices")}
      </Typography>
      <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
        <SearchBox>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <CustomInputBase
            placeholder={t("search_placeholder")}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </SearchBox>
        <AddButton
          title={t("add_new_office")}
          handleClick={() => navigate("/vendors/create")}
          backgroundColor="#2E6CB6"
          color="#fcfcfc"
          icon={
            <img
              className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
              alt=""
              src={"/Group 239.svg"}
            />
          }
        />
      </Box>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow
              style={{
                background: "rgba(220, 236, 255, 0.30)",
              }}
            >
              <TableCell> </TableCell>
              <TableCell>{t("offeiecname")}</TableCell>
              <TableCell>{t("manger")}</TableCell>
              <TableCell>{t("account_creation_date")}</TableCell>
              <TableCell>{t("email")}</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedAgents.map((agent) => (
              <TableRow key={agent.id}>
                <TableCell>
                  <img
                    className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                    alt=""
                    src={"/lawfirm-logo.svg"}
                  />
                </TableCell>
                <TableCell>{agent.Name && agent.Name}</TableCell>
                <TableCell>
                  {agent.admin_username && agent.admin_username}
                </TableCell>
                <TableCell>
                  {agent.created_at && changeDateFormat(agent.created_at)}
                </TableCell>
                <TableCell>{agent.admin_email && agent.admin_email}</TableCell>
                <TableCell>
                  <IconButton>
                    <EditIcon style={{ color: "#8DA7C8" }} />
                  </IconButton>
                  <IconButton onClick={() => handleOpen(agent)}>
                    <DeleteIcon style={{ color: "#8DA7C8" }} />
                  </IconButton>
                  <Link
                    to="/clients/show"
                    state={{ agent }}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton>
                      <InfoIcon style={{ color: "#8DA7C8" }} />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(filteredAgents.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="standard"
          renderItem={(item) => (
            <CustomPaginationItem
              components={{
                previous: () => (
                  <img
                    src="/keyboard_double_arrow_right.svg"
                    alt="Previous"
                    style={{ width: "24px", height: "24px" }}
                  />
                ),
                next: () => (
                  <img
                    src="/keyboard_double_arrow_left.svg"
                    alt="Next"
                    style={{ width: "24px", height: "24px" }}
                  />
                ),
              }}
              {...item}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default office;
