import { useForm } from "@refinedev/react-hook-form";

import { FieldValues } from "react-hook-form";

import Form from "components/common/Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axiosConfig";

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Data = location.state?.agent;
  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
    control,
  } = useForm();

  const onFinishHandler = async (data: FieldValues) => {
    const updateData = {
      username: data.username,
      email: data.email,
      phone: data.phone,
      user_type: Data.type,
      id_document: data.id_document,
      ...(data.password && { password: data.password }), // Only include password if it has a value
    };

    try {
      // Make the PUT request to update the user
      const response = await axiosInstance.patch(
        `admin/update_user/${Data.id}`,
        updateData
      );
      const responseData = response.data;
      console.log("🚀 ~ onFinishHandler ~ response:", responseData);
      if (response.status === 200) {
        console.log("User updated successfully:", responseData);
        navigate(-1);
      } else {
        console.log("Update failed:", responseData.message);
      }
    } catch (error) {
      console.log("Error updating user:", error);
    }
  };
  return (
    <Form
      type="Create"
      register={register}
      onFinish={onFinish}
      formLoading={formLoading}
      // handleSubmit={() => navigate("/clients")}
      handleSubmit={handleSubmit}
      agentData={Data}
      control={control}
      // handleImageChange={handleImageChange}
      onFinishHandler={onFinishHandler}
      handleImageChange={function (file: any): void {}}
      propertyImage={{
        name: "",
        url: "",
      }} // propertyImage={propertyImage}
      agent={{
        email: "",
      }}
    />
  );
};
export default EditUser;
